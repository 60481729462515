.glass-backdrop.glass-backdrop.show {
  display: none;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(25px);
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  opacity: 1;
}

@media (min-width: 1200px) {
  .glass-backdrop.glass-backdrop.show {
    display: block;
  }
}
